/* Notificator
---------------------------------------------------------------- */

export const notificator = (function () {
  // Timer
  let timer, extratimer;

  return {
    show: function (txt, end) {
      // Build notificator
      notificator.build();
      // Add to queue
      $("#notificator")
        .queue("notificator", function () {
          // Show
          $("#notificator").show();
          // Update text
          $("#notificator-text").html(txt);
          // Close
          if (end) {
            // Add class
            $("#notificator .load").addClass("saved");
            // Clear timeout
            clearTimeout(timer);
            // Delay
            timer = setTimeout(function () {
              // Hide
              notificator.hide();
            }, 2000);
          } else {
            // Remove class
            $("#notificator .load").removeClass("saved");
          }

          // Clear timeout
          clearTimeout(extratimer);
          // Delay
          extratimer = setTimeout(function () {
            // Hide
            notificator.hide();
          }, 30000);
        })
        .dequeue("notificator");
    },
    hide: function () {
      // Hide
      $("#notificator").fadeOut("fast");
      // Clear timers
      clearTimeout(timer);
      clearTimeout(extratimer);
    },
    break: function () {
      // Dequeue
      $("#notificator").dequeue("notificator");
      // Clear timeout
      clearTimeout(extratimer);
      // Delay
      extratimer = setTimeout(function () {
        // Hide
        notificator.hide();
      }, 800);
    },
    build: function () {
      // Get object
      let obj = document.getElementById("notificator");
      // Set variable
      let html = "";
      // .nav-t1 user context
      if (!obj) {
        // Set
        html += '<div class="noti-t1" id="notificator">';
        html += '	<div class="inn">';
        html += '		<div id="notificator-text"></div>';
        html += '		<div class="load"></div>';
        html += "	</div>";
        html += "</div>";

        // Append
        $("body").append(html);
      }
    },
  };
})();
