import { notificator } from "./notificator";

/* global FB */
/* global google */

/* Facebook
---------------------------------------------------------------- */
let facebooklogin = (function () {
  return {
    initialize: function () {
      // Attach
      $("#facebook-login").on("click", function () {
        facebooklogin.login();
        return false;
      });
    },
    login: function () {
      // Get click event
      let dis = $("#facebook-login").hasClass("disabled");
      // Allow
      if (!dis) {
        // Disable
        $("#facebook-login").addClass("disabled");
        // Call login
        FB.login(
          function (response) {
            // Accepted?
            if (response.authResponse) {
              // Get profile data
              FB.api("/me?fields=id,name,email", function (userrs) {
                // Get users variables
                let uid = userrs.id;
                let unam = userrs.name;
                let uema = userrs.email;

                // Update fields
                $("#external").val("true");
                $("#external_name").val(unam);
                $("#external_email").val(uema);
                $("#external_id").val(uid);
                $("#external_service").val("facebook");
                $(".warn-connect-service").text("Facebook");

                // Submit
                $(".form-sign-ext").trigger("submit");
                // Re-enable
                setTimeout(function () {
                  // Disable
                  $("#facebook-login").removeClass("disabled");
                }, 1000);
              });
            }
          },
          {
            // Needed parameters
            scope: "email,public_profile",

            // Return if user gave use access to needed parameters
            return_scopes: true,
          }
        );
      }

      // Re-enable
      setTimeout(function () {
        // Disable
        $("#facebook-login").removeClass("disabled");
      }, 2000);
    },
  };
})();

// Async function
window.fbAsyncInit = function () {
  FB.init({
    appId: "483127802044701",
    xfbml: true,
    version: "v2.9",
  });
};

// Add Facebook script
(function (d, s, id) {
  let js,
    fjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "//connect.facebook.net/en_US/sdk.js";
  fjs.parentNode.insertBefore(js, fjs);
})(document, "script", "facebook-jssdk");

// Attach
$(document).ready(function () {
  facebooklogin.initialize();
});

/* Google (Documenation: https://developers.google.com/identity/oauth2/web/reference/js-reference#TokenClientConfig)
---------------------------------------------------------------- */

let googlelogin = (function () {
  // Set
  let googleClient = null,
    google_ready = false;

  return {
    initialize: function () {
      // Attach
      $("#google-login").on("click", function () {
        googlelogin.login();
        return false;
      });
    },
    googleloaded: function () {
      // Set
      google_ready = true;
      // Setup token and callback
      googleClient = google.accounts.oauth2.initTokenClient({
        client_id:
          "961372887557-3bnggqm54pltgrdtoje4k4l3v3u7m1c8.apps.googleusercontent.com",
        scope: "profile email",
        callback: (response) => {
          // Callback
          googlelogin.response(response);
        },
      });
    },
    login: function () {
      // Ready?
      if (google_ready) {
        // Get click event
        let dis = $("#google-login").hasClass("disabled");

        // Allow
        if (!dis) {
          // Disable
          $("#google-login").addClass("disabled");
          // Request
          googleClient.requestAccessToken();

          // Re-enable
          setTimeout(function () {
            // Remove
            $("#google-login").removeClass("disabled");
          }, 1000);
        }
      } else {
        // Warning
        alert("Google is not ready. Please try again in a few seconds..");
      }
    },
    response: function (response) {
      // Request object
      let xhr = new XMLHttpRequest();
      xhr.open("GET", "https://www.googleapis.com/oauth2/v3/userinfo");
      xhr.setRequestHeader("Authorization", "Bearer " + response.access_token);

      // Done stage
      xhr.onreadystatechange = function () {
        // OK
        if (xhr.readyState == 4) {
          // Parse JSON
          let user = JSON.parse(xhr.responseText);

          // Update fields
          $("#external").val("true");
          $("#external_name").val(user.name);
          $("#external_email").val(user.email);
          $("#external_id").val(user.sub);
          $("#external_service").val("google");
          $(".warn-connect-service").text("Google");

          // Submit
          $(".form-sign-ext").submit();
        }
      };
      // Send
      xhr.send();
    },
  };
})();

// Add Google script
(function (d, s, id) {
  let js,
    gjs = d.getElementsByTagName(s)[0];
  if (d.getElementById(id)) {
    return;
  }
  js = d.createElement(s);
  js.id = id;
  js.src = "https://accounts.google.com/gsi/client";
  js.async = true;
  js.defer = true;
  js.onload = function () {
    googlelogin.googleloaded();
  };
  gjs.parentNode.insertBefore(js, gjs);
})(document, "script", "google-jssdk");

// Attach
$(document).ready(function () {
  googlelogin.initialize();
});

/* LinkedIn
---------------------------------------------------------------- */
let linkedinlogin = (function () {
  return {
    initialize: function () {
      // Attach
      $("#linkedin-login").on("click", function () {
        linkedinlogin.login();
        return false;
      });
      // Any auto post command?
      let dap = $(".form-sign-ext").attr("data-auto-post");
      // Auto post
      if (dap == "true" && $("#external_service").val() == "linkedin") {
        // Submit
        $(".form-sign-ext").submit();
        // Set type
        $(".warn-connect-service").text("LinkedIn");
      }
    },
    login: function () {
      // Get click event
      let dis = $("#linkedin-login").hasClass("disabled");
      // Get csrf
      let csrf = $("#linkedin-login").attr("data-csrf");
      // Get type
      let typ = $(".form-sign-ext").attr("data-type");

      // Allow
      if (!dis) {
        // Disable
        $("#linkedin-login").addClass("disabled");
        // Notify
        notificator.show("Loading");

        // Set type
        $(".warn-connect-service").text("LinkedIn");
        // Return URL
        let courl = encodeURIComponent(
          location.protocol + "//" + location.host + "/auth/linkedin/"
        );
        // Go to
        location.href =
          "https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=86sbge0pxo97zp&redirect_uri=" +
          courl +
          typ +
          "&state=" +
          csrf +
          "&scope=r_liteprofile%20r_emailaddress";
      }
    },
  };
})();

// Attach
$(document).ready(function () {
  linkedinlogin.initialize();
});

/* Microsoft
---------------------------------------------------------------- */
let microsoftlogin = (function () {
  return {
    initialize: function () {
      // Attach
      $("#microsoft-login").on("click", function () {
        microsoftlogin.login();
        return false;
      });
      // Any auto post command?
      let dap = $(".form-sign-ext").attr("data-auto-post");

      // Auto post
      if (dap == "true" && $("#external_service").val() == "microsoft") {
        // Disable
        $("#microsoft-login").addClass("disabled");
        // Submit
        $(".form-sign-ext").submit();
        // Set type
        $(".warn-connect-service").text("Microsoft");
      }
    },
    login: function () {
      // Get click event
      let dis = $("#microsoft-login").hasClass("disabled");
      // Get csrf
      let csrf = $("#microsoft-login").attr("data-csrf");
      // Get type
      let typ = $(".form-sign-ext").attr("data-type");
      // Allow
      if (!dis) {
        // Client ID
        let cid = "";

        //these credentials MUST be moved to configuration
        if (window.location.hostname.indexOf("addevent.dev") !== -1) {
          //dev credentials
          cid = "5e0cf41f-f5af-4f1e-a4b5-0249f1decb67";
        } else if (window.location.host.indexOf("addevent.com") !== -1) {
          //prod credentials
          cid = "73a48313-f687-4a4d-95c2-ae39aec173fd";
        } else if (window.location.host.indexOf("addevent.co") !== -1) {
          //local env credentials
          cid = "27cea329-ae37-4969-a928-ff7b9d18e661";
        } else {
          console.log("no app configured for " + window.location.host);
        }

        // Disable
        $("#microsoft-login").addClass("disabled");
        // Notify
        notificator.show("Loading");

        // Set type
        $(".warn-connect-service").text("Microsoft");
        // Return URL
        let courl = encodeURIComponent(
          location.protocol + "//" + location.host + "/auth/microsoft/"
        );
        // Go to
        location.href =
          "https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=" +
          cid +
          "&response_type=code&redirect_uri=" +
          courl +
          typ +
          "&response_mode=query&scope=openid%20profile%20email%20https%3A%2F%2Fgraph.microsoft.com%2Fuser.read&state=" +
          csrf;
      }
    },
  };
})();

// Attach
$(document).ready(function () {
  microsoftlogin.initialize();
});
